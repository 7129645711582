const INDICES = {
  45: "gold",
  192: "red",
  339: "green",
  782: "red",
  1073: "gold",
  1317: "green",
  1744: "red",
  2104: "red",
  2473: "green",
  2793: "green",
  3094: "red",
  3363: "purple",
  3700: "green",
  4012: "red",
  4466: "gold",
  4884: "red",
  5279: "green",
  5685: "gold",
  5999: "green",
  6378: "gold",
  6846: "green",
  7294: "red",
  7597: "purple",
  7945: "gold",
  8335: "green",
  8751: "gold",
  9076: "green",
  9343: "red",
  9586: "green",
  9932: "purple",
  10225: "gold",
  10510: "purple",
  10766: "green",
  11040: "red",
  11368: "green",
  11635: "green",
  11972: "green",
  12232: "green",
  12633: "green",
  13065: "gold",
  13332: "green",
  13794: "red",
  14132: "gold",
  14532: "purple",
  14988: "green",
  15279: "red",
  15596: "purple",
  16069: "green",
  16498: "green",
  16922: "purple",
  17194: "gold",
  17528: "gold",
  17816: "red",
  18060: "green",
  18446: "green",
  18857: "green",
  19229: "red",
  19649: "red",
  20104: "purple",
  20436: "green",
  20681: "gold",
  21103: "red",
  21549: "green",
  21806: "purple",
  22047: "gold",
  22457: "purple",
  22876: "red",
  23206: "gold",
  23604: "purple",
  23935: "gold",
  24278: "red",
  24563: "gold",
  24965: "purple",
  25328: "purple",
  25622: "purple",
  26086: "purple",
  26356: "purple",
  26691: "red",
  27035: "purple",
  27331: "gold",
  27743: "red",
  28105: "red",
  28378: "gold",
  28797: "gold",
  29167: "red",
  29631: "red",
  29996: "purple",
  30291: "red",
  30656: "red",
  30963: "red",
  31328: "purple",
  31734: "red",
  32193: "green",
  32602: "gold",
  33022: "red",
  33471: "purple",
  33737: "red",
  34072: "red",
  34537: "green",
  34969: "red",
  35372: "gold",
  35638: "gold",
  36081: "purple",
  36425: "gold",
  36700: "green",
  37147: "red",
  37428: "gold",
  37843: "red",
  38223: "green",
  38654: "purple",
  39075: "purple",
  39369: "green",
  39686: "red",
  40053: "red",
  40378: "purple",
  40651: "green",
  41067: "red",
  41399: "red",
  41861: "red",
  42163: "gold",
  42409: "purple",
  42704: "red",
  42972: "green",
  43229: "purple",
  43682: "green",
  44150: "purple",
  44548: "purple",
  45002: "red",
  45444: "gold",
  45780: "gold",
  46127: "purple",
  46531: "gold",
  46994: "gold",
  47234: "green",
  47669: "red",
  48101: "red",
  48549: "purple",
  48825: "green",
  49295: "purple",
  49738: "purple",
  49977: "red",
  50380: "red",
  50692: "purple",
  51108: "green",
  51550: "green",
  51958: "purple",
  52433: "purple",
  52791: "green",
  53052: "red",
  53524: "purple",
  53993: "gold",
  54274: "gold",
  54527: "green",
  54884: "purple",
  55241: "red",
  55714: "green",
  56101: "green",
  56417: "green",
  56840: "red",
  57094: "green",
  57385: "green",
  57763: "purple",
  58016: "green",
  58342: "green",
  58726: "purple",
  59010: "red",
  59406: "purple",
  59871: "gold",
  60309: "red",
  60672: "red",
  60998: "red",
  61347: "green",
  61745: "purple",
  62211: "red",
  62536: "purple",
  62791: "purple",
  63119: "purple",
  63489: "purple",
  63894: "gold",
  64294: "green",
  64555: "red",
  64871: "gold",
  65276: "gold",
  65734: "red",
  66074: "purple",
  66429: "gold",
  66836: "green",
  67097: "red",
  67360: "gold",
  67835: "purple",
  68160: "red",
  68492: "gold",
  68871: "red",
  69186: "purple",
  69647: "red",
  69987: "green",
  70324: "gold",
  70574: "red",
  70995: "gold",
  71250: "red",
  71671: "purple",
  72059: "gold",
  72346: "purple",
  72601: "gold",
  73007: "green",
  73354: "green",
  73627: "green",
  73980: "gold",
  74351: "purple",
  74602: "green",
  74906: "gold",
  75369: "green",
  75735: "gold",
  76047: "red",
  76344: "red",
  76585: "purple",
  76948: "gold",
  77198: "gold",
  77636: "gold",
  77873: "purple",
  78120: "green",
  78432: "purple",
  78770: "green",
  79219: "red",
  79612: "gold",
  79935: "green",
  80225: "green",
  80587: "red",
  81040: "purple",
  81500: "red",
  81958: "purple",
  82214: "purple",
  82451: "green",
  82713: "purple",
  82959: "purple",
  83378: "green",
  83701: "red",
  84046: "purple",
  84383: "green",
  84652: "gold",
  85060: "gold",
  85524: "gold",
  85813: "purple",
  86119: "gold",
  86592: "red",
  86881: "gold",
  87139: "red",
  87583: "gold",
  88002: "gold",
  88281: "purple",
  88602: "green",
  89041: "red",
  89400: "purple",
  89832: "red",
  90279: "gold",
  90712: "gold",
  91088: "red",
  91402: "red",
  91730: "green",
  92012: "red",
  92311: "red",
  92607: "gold",
  92905: "purple",
  93189: "red",
  93630: "purple",
  93873: "red",
  94332: "purple",
  94789: "green",
  95207: "gold",
  95583: "gold",
  95995: "gold",
  96377: "green",
  96843: "purple",
  97189: "red",
  97593: "red",
  97860: "green",
  98100: "green",
  98479: "gold",
  98731: "purple",
  98987: "red",
  99391: "gold",
  99658: "red",
  100011: "green",
  100304: "purple",
  100724: "red",
  101107: "green",
  101467: "gold",
  101893: "red",
  102229: "green",
  102622: "green",
  102864: "gold",
  103205: "green",
  103587: "purple",
  104000: "purple",
  104386: "green",
  104830: "purple",
  105122: "red",
  105484: "gold",
  105959: "purple",
  106428: "red",
  106717: "green",
  107173: "gold",
  107629: "green",
  108031: "purple",
  108315: "red",
  108756: "green",
  109098: "purple",
  109559: "purple",
  110012: "purple",
  110256: "gold",
  110640: "gold",
  110993: "purple",
  111467: "gold",
  111745: "gold",
  112171: "gold",
  112439: "green",
  112727: "purple",
  113018: "red",
  113277: "green",
  113556: "purple",
  113866: "gold",
  114254: "red",
  114561: "purple",
  114814: "gold",
  115200: "red",
  115589: "red",
  115830: "green",
  116084: "red",
  116450: "gold",
  116829: "gold",
  117278: "purple",
  117669: "green",
  118126: "gold",
  118524: "green",
  118917: "purple",
  119276: "purple",
  119674: "gold",
  120040: "red",
  120514: "green",
  120812: "purple",
  121109: "green",
  121387: "purple",
  121760: "red",
  122124: "green",
  122479: "red",
  122799: "gold",
  123235: "gold",
  123534: "purple",
  123881: "purple",
  124187: "purple",
  124451: "red",
  124872: "purple",
  125316: "green",
  125726: "red",
  126008: "gold",
  126345: "gold",
  126658: "green",
  127023: "gold",
  127340: "gold",
  127685: "gold",
  128149: "green",
  128620: "green",
  129022: "gold",
  129285: "red",
  129532: "green",
  129809: "purple",
  130209: "gold",
  130478: "purple",
  130828: "green",
  131151: "red",
  131568: "green",
  131945: "red",
  132329: "red",
  132769: "purple",
  133169: "purple",
  133498: "green",
  133867: "purple",
  134226: "green",
  134487: "gold",
  134897: "gold",
  135149: "purple",
  135542: "gold",
  135819: "gold",
  136165: "green",
  136451: "green",
  136721: "gold",
  136963: "purple",
  137222: "gold",
  137665: "purple",
  137962: "red",
  138238: "red",
  138591: "green",
  138918: "gold",
  139249: "gold",
  139598: "green",
  140013: "purple",
  140455: "gold",
  140707: "green",
  140949: "green",
  141294: "gold",
  141642: "red",
  142016: "green",
  142350: "purple",
  142652: "purple",
  142917: "gold",
  143289: "red",
  143762: "green",
  144198: "purple",
  144554: "red",
  144852: "gold",
  145095: "green",
  145465: "purple",
  145860: "red",
  146314: "gold",
  146726: "purple",
  147095: "purple",
  147342: "green",
  147769: "red",
  148208: "green",
  148657: "purple",
  149102: "red",
  149386: "red",
  149648: "red",
  149887: "gold",
  150170: "purple",
  150533: "gold",
  150819: "purple",
  151150: "purple",
  151462: "red",
  151821: "green",
  152293: "purple",
  152575: "purple",
  153049: "gold",
  153298: "purple",
  153722: "green",
  154022: "purple",
  154366: "green",
  154780: "gold",
  155235: "red",
  155657: "red",
  156080: "gold",
  156485: "green",
  156749: "red",
  157219: "green",
  157627: "purple",
  158041: "red",
  158320: "gold",
  158795: "red",
  159174: "green",
  159444: "gold",
  159794: "green",
  160085: "purple",
  160512: "red",
  160910: "green",
  161291: "gold",
  161761: "gold",
  162109: "gold",
  162574: "red",
  162879: "red",
  163122: "purple",
  163489: "red",
  163938: "purple",
  164337: "purple",
  164722: "green",
  165061: "green",
  165415: "purple",
  165748: "purple",
  166176: "red",
  166521: "green",
  166980: "green",
  167316: "gold",
  167601: "green",
  167866: "gold",
  168264: "gold",
  168639: "red",
  168882: "red",
  169239: "green",
  169698: "purple",
  169988: "gold",
  170382: "green",
  170770: "red",
  171240: "purple",
  171599: "green",
  171973: "purple",
  172431: "gold",
  172867: "purple",
  173170: "gold",
  173409: "gold",
  173783: "gold",
  174162: "red",
  174447: "purple",
  174686: "red",
  174986: "red",
  175445: "gold",
  175711: "green",
  176147: "red",
  176451: "green",
  176772: "purple",
  177102: "gold",
  177341: "green",
  177654: "gold",
  178063: "purple",
  178369: "purple",
  178835: "red",
  179240: "gold",
  179547: "gold",
  179872: "purple",
  180168: "green",
  180526: "purple",
  180782: "gold",
  181192: "red",
  181481: "gold",
  181810: "red",
  182204: "purple",
  182677: "gold",
  183081: "red",
  183545: "purple",
  183922: "purple",
  184235: "purple",
  184509: "purple",
  184762: "purple",
  185140: "purple",
  185517: "green",
  185866: "purple",
  186202: "purple",
  186513: "gold",
  186809: "green",
  187160: "red",
  187463: "red",
  187774: "gold",
  188136: "gold",
  188378: "green",
  188772: "green",
  189077: "purple",
  189479: "gold",
  189894: "red",
  190286: "purple",
  190612: "purple",
  191001: "red",
  191404: "gold",
  191780: "green",
  192089: "gold",
  192409: "gold",
  192760: "gold",
  193016: "green",
  193304: "red",
  193600: "purple",
  194049: "red",
  194360: "red",
  194732: "gold",
  195060: "green",
  195324: "purple",
  195690: "green",
  196038: "green",
  196335: "red",
  196634: "green",
  197104: "red",
  197556: "red",
  198000: "red",
  198402: "purple",
  198855: "red",
  199164: "gold",
  199453: "green",
  199898: "green",
  200210: "red",
  200476: "purple",
  200923: "green",
  201396: "red",
  201693: "gold",
  202098: "green",
  202336: "green",
  202763: "purple",
  203230: "red",
  203608: "gold",
  203884: "red",
  204265: "gold",
  204601: "purple",
  204968: "purple",
  205227: "purple",
  205509: "green",
  205800: "gold",
  206198: "green",
  206472: "purple",
  206862: "green",
  207236: "red",
  207698: "green",
  207955: "purple",
  208195: "gold",
  208598: "purple",
  208957: "gold",
  209250: "gold",
  209658: "red",
  210007: "gold",
  210257: "green",
  210550: "red",
  210841: "gold",
  211169: "gold",
  211505: "green",
  211819: "red",
  212238: "green",
  212631: "red",
  213079: "red",
  213545: "gold",
  213982: "green",
  214231: "purple",
  214557: "green",
  214944: "red",
  215293: "purple",
  215615: "green",
  215999: "gold",
  216262: "gold",
  216724: "purple",
  217087: "purple",
  217558: "red",
  217828: "gold",
  218143: "gold",
  218569: "purple",
  218897: "purple",
  219298: "red",
  219662: "green",
  219961: "green",
  220401: "gold",
  220741: "gold",
  221105: "gold",
  221551: "red",
  221990: "red",
  222380: "red",
  222730: "gold",
  222974: "purple",
  223232: "green",
  223616: "purple",
  223898: "purple",
  224248: "green",
  224528: "red",
  224845: "gold",
  225296: "purple",
  225541: "gold",
  226015: "green",
  226435: "red",
  226855: "red",
  227298: "gold",
  227612: "red",
  228024: "purple",
  228466: "purple",
  228775: "red",
  229163: "gold",
  229487: "red",
  229886: "gold",
  230174: "gold",
  230455: "gold",
  230830: "red",
  231229: "red",
  231539: "red",
  231955: "red",
  232294: "gold",
  232612: "gold",
  233079: "red",
  233398: "green",
  233759: "green",
  234009: "red",
  234453: "green",
  234920: "gold",
  235277: "green",
  235699: "gold",
  236043: "red",
  236498: "gold",
  236780: "gold",
  237098: "green",
  237540: "red",
  237994: "green",
  238339: "red",
  238691: "gold",
  238931: "purple",
  239184: "green",
  239567: "gold",
  239956: "purple",
  240379: "green",
  240741: "gold",
  241087: "purple",
  241460: "purple",
  241733: "red",
  242004: "green",
  242446: "purple",
  242896: "green",
  243298: "green",
  243676: "green",
  244046: "green",
  244452: "green",
  244764: "green",
  245028: "purple",
  245276: "gold",
  245632: "purple",
  245933: "green",
  246270: "gold",
  246511: "green",
  246972: "purple",
  247410: "purple",
  247764: "red",
  248060: "gold",
  248375: "gold",
  248702: "gold",
  249024: "gold",
  249338: "gold",
  249700: "red",
  250084: "red",
  250443: "purple",
  250684: "gold",
  251098: "green",
  251473: "red",
  251899: "green",
  252331: "gold",
  252671: "gold",
  253098: "gold",
  253353: "green",
  253634: "green",
  253970: "gold",
  254230: "gold",
  254646: "red",
  255049: "gold",
  255349: "red",
  255664: "red",
  256074: "green",
  256477: "green",
  256793: "purple",
  257217: "red",
  257681: "red",
  258100: "red",
  258441: "green",
  258682: "gold",
  259072: "purple",
  259408: "red",
  259653: "purple",
  260004: "red",
  260330: "red",
  260672: "gold",
  261028: "purple",
  261295: "purple",
  261658: "red",
  262027: "gold",
  262466: "purple",
  262740: "gold",
  263131: "purple",
  263544: "green",
  263969: "red",
  264331: "purple",
  264693: "purple",
  265147: "green",
  265559: "purple",
  265935: "gold",
  266262: "green",
  266615: "red",
  266895: "green",
  267146: "red",
  267418: "gold",
  267831: "red",
  268280: "purple",
  268575: "purple",
  268988: "red",
  269403: "purple",
  269717: "red",
  270063: "gold",
  270328: "gold",
  270679: "green",
  271049: "purple",
  271466: "red",
  271740: "green",
  271993: "gold",
  272274: "red",
  272736: "gold",
  273035: "purple",
  273481: "gold",
  273722: "gold",
  274135: "green",
  274379: "purple",
  274797: "green",
  275107: "red",
  275387: "red",
  275645: "gold",
  276078: "green",
  276326: "gold",
  276716: "gold",
  277074: "gold",
  277523: "red",
  277966: "red",
  278344: "green",
  278753: "gold",
  279090: "green",
  279460: "green",
  279758: "purple",
  280135: "gold",
  280573: "purple",
  280975: "red",
  281406: "purple",
  281756: "gold",
  282138: "gold",
  282403: "green",
  282796: "green",
  283076: "purple",
  283463: "green",
  283797: "green",
  284205: "gold",
  284495: "gold",
  284775: "gold",
  285178: "green",
  285517: "gold",
  285918: "red",
  286201: "purple",
  286453: "green",
  286706: "purple",
  287103: "gold",
  287391: "purple",
  287751: "purple",
  287991: "purple",
  288400: "red",
  288753: "green",
  289191: "gold",
  289630: "green",
  289988: "green",
  290281: "red",
  290657: "gold",
  291099: "red",
  291451: "red",
  291900: "green",
  292229: "purple",
  292683: "green",
  293114: "purple",
  293382: "purple",
  293634: "purple",
  294027: "green",
  294479: "purple",
  294899: "purple",
  295159: "purple",
  295436: "gold",
  295687: "purple",
  295969: "green",
  296356: "gold",
  296663: "green",
  297055: "red",
  297383: "gold",
  297713: "green",
  298026: "purple",
  298356: "red",
  298758: "red",
  299120: "red",
  299464: "red",
  299735: "gold",
  300064: "gold",
  300468: "purple",
  300779: "red",
  301143: "gold",
  301612: "green",
  302051: "purple",
  302470: "purple",
  302817: "green",
  303184: "gold",
  303535: "green",
  303787: "red",
  304259: "red",
  304644: "gold",
  304981: "gold",
  305387: "purple",
  305734: "green",
  306178: "purple",
  306487: "green",
  306856: "green",
  307182: "green",
  307425: "purple",
  307756: "purple",
  308050: "gold",
  308376: "gold",
  308677: "gold",
  308988: "green",
  309372: "gold",
  309810: "red",
  310183: "green",
  310575: "red",
  310923: "red",
  311294: "green",
  311696: "purple",
  311951: "red",
  312224: "gold",
  312699: "purple",
  313017: "red",
  313332: "gold",
  313570: "purple",
  314019: "green",
  314471: "red",
  314826: "gold",
  315213: "purple",
  315633: "red",
  315998: "gold",
  316362: "gold",
  316696: "purple",
  316984: "purple",
  317295: "green",
  317687: "purple",
  318028: "purple",
  318346: "red",
  318711: "red",
  319125: "gold",
  319581: "gold",
  320005: "purple",
  320345: "purple",
  320608: "purple",
  320955: "red",
  321192: "green",
  321500: "green",
  321961: "red",
  322427: "gold",
  322758: "red",
  323174: "gold",
  323463: "purple",
  323834: "red",
  324105: "purple",
  324399: "gold",
  324833: "green",
  325259: "red",
  325689: "gold",
  326004: "green",
  326393: "purple",
  326865: "green",
  327306: "gold",
  327745: "gold",
  328049: "gold",
  328383: "gold",
  328772: "green",
  329137: "gold",
  329447: "gold",
  329817: "purple",
  330094: "red",
  330411: "green",
  330864: "gold",
  331314: "purple",
  331617: "red",
  331941: "green",
  332337: "purple",
  332675: "gold",
  333098: "gold",
  333406: "gold",
  333703: "purple",
  334040: "gold",
  334417: "purple",
  334654: "red",
  335067: "gold",
  335417: "purple",
  335806: "gold",
  336190: "green",
  336507: "purple",
  336849: "green",
  337167: "green",
  337549: "gold",
  337937: "red",
  338258: "gold",
  338626: "red",
  339039: "gold",
  339286: "purple",
  339689: "green",
  340118: "purple",
  340581: "green",
  340994: "gold",
  341294: "gold",
  341551: "green",
  341876: "purple",
  342264: "purple",
  342633: "green",
  342961: "purple",
  343298: "purple",
  343556: "purple",
  343820: "red",
  344143: "red",
  344576: "green",
  344925: "purple",
  345305: "green",
  345589: "red",
  346022: "red",
  346312: "red",
  346732: "red",
  347018: "green",
  347378: "green",
  347647: "gold",
  348006: "gold",
  348414: "red",
  348669: "purple",
  348930: "gold",
  349337: "gold",
  349622: "green",
  349970: "red",
  350325: "red",
  350619: "red",
  350860: "purple",
  351233: "gold",
  351611: "purple",
  352000: "gold",
  352243: "red",
  352666: "gold",
  353136: "purple",
  353505: "red",
  353904: "gold",
  354364: "red",
  354673: "red",
  355131: "green",
  355548: "green",
  355916: "purple",
  356377: "green",
  356702: "red",
  357024: "purple",
  357429: "red",
  357871: "gold",
  358124: "purple",
  358533: "purple",
  358778: "gold",
  359096: "purple",
  359501: "purple",
  359892: "green",
  360194: "red",
  360611: "green",
  360895: "red",
  361200: "purple",
  361563: "green",
  361992: "green",
  362414: "gold",
  362702: "green",
  363083: "red",
  363505: "purple",
  363761: "green",
  364125: "green",
  364458: "purple",
  364881: "purple",
  365214: "green",
  365564: "gold",
  365992: "green",
  366338: "red",
  366794: "gold",
  367179: "green",
  367458: "green",
  367696: "gold",
  367970: "purple",
  368394: "purple",
  368816: "gold",
  369269: "green",
  369703: "green",
  370133: "red",
  370379: "gold",
  370723: "green",
  371043: "purple",
  371435: "green",
  371757: "green",
  372056: "red",
  372381: "red",
  372659: "green",
  373089: "purple",
  373463: "red",
  373767: "purple",
  374094: "green",
  374374: "gold",
  374838: "gold",
  375249: "green",
  375692: "red",
  376077: "red",
  376358: "red",
  376666: "green",
  376996: "green",
  377444: "red",
  377695: "red",
  377973: "purple",
  378335: "green",
  378748: "green",
  379170: "red",
  379570: "gold",
  380036: "gold",
  380385: "green",
  380719: "red",
  381023: "purple",
  381464: "gold",
  381781: "red",
  382247: "green",
  382612: "red",
  382896: "purple",
  383223: "purple",
  383535: "purple",
  383902: "purple",
  384284: "green",
  384741: "red",
  385082: "red",
  385350: "purple",
  385755: "red",
  386197: "purple",
  386651: "purple",
  386941: "gold",
  387365: "purple",
  387821: "gold",
  388084: "gold",
  388370: "gold",
  388710: "gold",
  389165: "green",
  389540: "red",
  390008: "gold",
  390475: "green",
  390857: "purple",
  391187: "red",
  391587: "purple",
  391831: "gold",
  392078: "red",
  392530: "gold",
  392936: "gold",
  393282: "purple",
  393571: "gold",
  393965: "green",
  394227: "purple",
  394628: "gold",
  394961: "purple",
  395259: "red",
  395677: "green",
  395948: "green",
  396406: "red",
  396799: "gold",
  397166: "green",
  397458: "gold",
  397883: "gold",
  398243: "gold",
  398687: "green",
  399108: "purple",
  399457: "gold",
  399765: "green",
  400051: "gold",
  400380: "red",
  400851: "purple",
  401173: "green",
  401619: "red",
  402014: "gold",
  402403: "gold",
  402667: "purple",
  403064: "purple",
  403454: "gold",
  403893: "green",
  404137: "green",
  404513: "red",
  404794: "red",
  405153: "red",
  405426: "gold",
  405773: "green",
  406081: "gold",
  406376: "green",
  406676: "green",
  407097: "purple",
  407523: "green",
  407915: "red",
  408253: "red",
  408572: "purple",
  409013: "red",
  409450: "green",
  409806: "purple",
  410261: "gold",
  410586: "purple",
  411038: "purple",
  411405: "red",
  411735: "red",
  412094: "red",
  412450: "gold",
  412874: "purple",
  413260: "purple",
  413582: "purple",
  413950: "purple",
  414331: "gold",
  414606: "green",
  414977: "purple",
  415376: "gold",
  415838: "green",
  416183: "red",
  416514: "green",
  416982: "gold",
  417339: "gold",
  417782: "red",
  418104: "green",
  418442: "gold",
  418724: "green",
  419161: "red",
  419611: "red",
  419904: "green",
  420233: "red",
  420490: "gold",
  420731: "purple",
  420994: "purple",
  421358: "purple",
  421758: "gold",
  422023: "red",
  422387: "red",
  422661: "green",
  423008: "gold",
  423291: "green",
  423561: "gold",
  423994: "purple",
  424250: "green",
  424559: "gold",
  424860: "red",
  425290: "red",
  425635: "gold",
  426041: "purple",
  426459: "red",
  426757: "gold",
  427087: "red",
  427537: "purple",
  427958: "gold",
  428322: "green",
  428727: "green",
  429049: "green",
  429389: "green",
  429861: "green",
  430258: "red",
  430592: "red",
  430888: "green",
  431231: "gold",
  431579: "gold",
  431909: "purple",
  432332: "purple",
  432623: "gold",
  432887: "gold",
  433301: "green",
  433647: "purple",
  433971: "purple",
  434256: "green",
  434716: "purple",
  434978: "green",
  435258: "gold",
  435613: "green",
  435998: "purple",
  436463: "purple",
  436830: "green",
  437212: "gold",
  437647: "purple",
  438022: "green",
  438321: "red",
  438740: "purple",
  439181: "purple",
  439476: "purple",
  439893: "gold",
  440311: "gold",
  440626: "red",
  440909: "red",
  441245: "green",
  441544: "red",
  441839: "purple",
  442094: "green",
  442420: "purple",
  442669: "red",
  442978: "red",
  443309: "red",
  443553: "gold",
  443864: "purple",
  444144: "green",
  444520: "red",
  444783: "purple",
  445208: "gold",
  445527: "red",
  445970: "purple",
  446333: "gold",
  446683: "green",
  446934: "purple",
  447235: "purple",
  447509: "purple",
  447815: "gold",
  448221: "purple",
  448535: "red",
  448859: "purple",
  449189: "purple",
  449505: "gold",
  449776: "purple",
  450237: "green",
  450582: "gold",
  450828: "red",
  451198: "red",
  451477: "purple",
  451809: "gold",
  452153: "green",
  452610: "gold",
  452942: "purple",
  453393: "purple",
  453740: "purple",
  454135: "red",
  454463: "red",
  454737: "purple",
  455069: "purple",
  455533: "purple",
  455964: "green",
  456238: "green",
  456544: "green",
  456852: "red",
  457286: "purple",
  457591: "gold",
  457934: "gold",
  458200: "green",
  458483: "green",
  458860: "purple",
  459155: "gold",
  459445: "purple",
  459790: "gold",
  460095: "gold",
  460556: "gold",
  460803: "purple",
  461138: "red",
  461567: "red",
  461969: "purple",
  462358: "green",
  462602: "purple",
  462902: "red",
  463227: "gold",
  463658: "gold",
  463928: "gold",
  464238: "gold",
  464661: "green",
  465039: "gold",
  465380: "purple",
  465639: "purple",
  465918: "gold",
  466207: "gold",
  466561: "purple",
  466984: "purple",
  467379: "green",
  467674: "purple",
  468140: "gold",
  468553: "green",
  468919: "purple",
  469196: "gold",
  469535: "green",
  469902: "purple",
  470249: "green",
  470641: "gold",
  471032: "purple",
  471317: "red",
  471681: "red",
  471940: "gold",
  472275: "purple",
  472572: "gold",
  472858: "gold",
  473247: "purple",
  473546: "red",
  473973: "purple",
  474397: "purple",
  474698: "purple",
  474993: "purple",
  475274: "red",
  475647: "gold",
  476049: "gold",
  476404: "gold",
  476861: "purple",
  477242: "green",
  477640: "purple",
  478005: "purple",
  478428: "purple",
  478765: "red",
  479136: "gold",
  479526: "purple",
  479855: "purple",
  480245: "green",
  480558: "purple",
  481015: "purple",
  481343: "red",
  481657: "purple",
  481971: "red",
  482394: "green",
  482773: "green",
  483139: "red",
  483607: "gold",
  483884: "red",
  484158: "green",
  484574: "gold",
  485030: "purple",
  485378: "red",
  485849: "red",
  486086: "gold",
  486446: "gold",
  486758: "purple",
  487031: "red",
  487384: "red",
  487782: "purple",
  488101: "purple",
  488481: "green",
  488730: "gold",
  489165: "gold",
  489525: "gold",
  489811: "gold",
  490270: "purple",
  490538: "green",
  490907: "purple",
  491324: "gold",
  491721: "green",
  492188: "gold",
  492580: "purple",
  492879: "gold",
  493282: "purple",
  493686: "purple",
  494120: "purple",
  494366: "purple",
  494733: "red",
  495091: "purple",
  495459: "red",
  495858: "gold",
  496116: "purple",
  496500: "red",
  496902: "red",
  497311: "green",
  497632: "gold",
  498056: "red",
  498443: "purple",
  498833: "red",
  499122: "green",
  499378: "purple",
  499755: "green",
  500065: "green",
  500303: "green",
  500596: "gold",
  500919: "red",
  501304: "red",
  501741: "purple",
  501991: "red",
  502409: "purple",
  502835: "red",
  503262: "gold",
  503559: "green",
  504026: "green",
  504354: "green",
  504688: "gold",
  505147: "gold",
  505440: "gold",
  505720: "purple",
  505974: "green",
  506230: "gold",
  506563: "gold",
  506837: "green",
  507120: "purple",
  507438: "red",
  507719: "green",
  508036: "red",
  508281: "gold",
  508537: "gold",
  508859: "red",
  509176: "gold",
  509619: "gold",
  509898: "red",
  510302: "red",
  510760: "green",
  511114: "green",
  511426: "purple",
  511761: "red",
  512112: "gold",
  512501: "red",
  512925: "gold",
  513332: "green",
  513586: "green",
  513866: "green",
  514299: "green",
  514672: "purple",
  514923: "red",
  515272: "purple",
  515548: "gold",
  515862: "red",
  516208: "gold",
  516616: "red",
  517002: "purple",
  517404: "red",
  517753: "green",
  518203: "red",
  518674: "purple",
  518938: "gold",
  519375: "red",
  519763: "green",
  520020: "gold",
  520347: "red",
  520718: "green",
  521034: "red",
  521442: "purple",
  521895: "red",
  522252: "gold",
  522674: "purple",
  522936: "purple",
  523342: "red",
  523817: "red",
  524105: "gold",
  524570: "purple",
  524973: "red",
  525282: "green",
  525648: "green",
  525989: "gold",
  526255: "red",
  526715: "green",
  527018: "gold",
  527314: "purple",
  527586: "green",
  527866: "green",
  528124: "purple",
  528406: "green",
  528859: "purple",
  529134: "green",
  529527: "gold",
  529810: "purple",
  530213: "red",
  530548: "purple",
  530843: "gold",
  531123: "purple",
  531473: "purple",
  531915: "gold",
  532356: "green",
  532829: "green",
  533170: "purple",
  533477: "red",
  533778: "gold",
  534240: "green",
  534647: "purple",
  534937: "gold",
  535392: "red",
  535743: "green",
  536207: "purple",
  536537: "green",
  536810: "green",
  537171: "green",
  537451: "red",
  537743: "green",
  538053: "red",
  538439: "red",
  538756: "gold",
  539023: "red",
  539270: "purple",
  539554: "red",
  539914: "gold",
  540220: "purple",
  540528: "red",
  540792: "red",
  541202: "green",
  541578: "gold",
  541879: "red",
  542314: "gold",
  542607: "purple",
  542875: "green",
  543257: "gold",
  543715: "green",
  543983: "red",
  544360: "red",
  544697: "red",
  544973: "green",
  545358: "gold",
  545714: "purple",
  545979: "green",
  546244: "green",
  546571: "purple",
  547001: "purple",
  547473: "green",
  547751: "green",
  548165: "purple",
  548500: "green",
  548909: "red",
  549205: "gold",
  549566: "gold",
  550020: "red",
  550371: "purple",
  550728: "purple",
  551029: "green",
  551408: "red",
  551805: "green",
  552257: "red",
  552705: "red",
  552969: "gold",
  553280: "red",
  553602: "green",
  553912: "purple",
  554329: "green",
  554687: "gold",
  555011: "green",
  555368: "green",
  555834: "gold",
  556104: "gold",
  556385: "gold",
  556827: "purple",
  557235: "red",
  557662: "green",
  557903: "gold",
  558295: "gold",
  558589: "purple",
  559019: "gold",
  559489: "red",
  559933: "red",
  560316: "red",
  560717: "green",
  560969: "green",
  561341: "green",
  561680: "gold",
  562033: "gold",
  562502: "purple",
  562789: "red",
  563106: "purple",
  563464: "red",
  563764: "green",
  564217: "green",
  564512: "red",
  564918: "red",
  565157: "gold",
  565436: "purple",
  565805: "green",
  566280: "green",
  566520: "green",
  566923: "gold",
  567324: "gold",
  567621: "red",
  567938: "green",
  568347: "purple",
  568677: "green",
  569114: "purple",
  569544: "gold",
  569871: "purple",
  570334: "purple",
  570635: "green",
  570952: "gold",
  571375: "green",
  571777: "red",
  572051: "gold",
  572347: "purple",
  572597: "green",
  572835: "purple",
  573258: "purple",
  573586: "red",
  574001: "gold",
  574452: "purple",
  574857: "red",
  575166: "gold",
  575445: "green",
  575828: "red",
  576205: "green",
  576517: "purple",
  576879: "gold",
  577347: "purple",
  577810: "green",
  578188: "gold",
  578634: "purple",
  579011: "red",
  579485: "purple",
  579759: "green",
  580199: "purple",
  580566: "purple",
  580907: "purple",
  581293: "green",
  581635: "purple",
  582026: "purple",
  582495: "gold",
  582770: "red",
  583041: "purple",
  583442: "purple",
  583801: "green",
  584112: "red",
  584390: "purple",
  584701: "gold",
  584949: "purple",
  585309: "green",
  585592: "gold",
  585895: "gold",
  586358: "purple",
  586645: "gold",
  586906: "red",
  587145: "green",
  587504: "purple",
  587920: "purple",
  588290: "green",
  588696: "green",
  588997: "purple",
  589238: "purple",
  589608: "green",
  589918: "purple",
  590349: "gold",
  590658: "red",
  591060: "purple",
  591376: "gold",
  591686: "red",
  591978: "red",
  592334: "purple",
  592629: "purple",
  592882: "gold",
  593153: "red",
  593449: "green",
  593740: "green",
  594142: "green",
  594486: "red",
  594842: "purple",
  595219: "red",
  595560: "green",
  595936: "purple",
  596256: "purple",
  596621: "purple",
  596938: "green",
  597348: "purple",
  597808: "purple",
  598142: "gold",
  598473: "gold",
  598787: "green",
  599064: "green",
  599503: "purple",
  599830: "purple",
  600072: "green",
  600547: "green",
  600896: "red",
  601324: "green",
  601773: "green",
  602016: "gold",
  602292: "gold",
  602748: "gold",
  603036: "red",
  603352: "gold",
  603613: "purple",
  603912: "gold",
  604269: "purple",
  604686: "gold",
  605157: "gold",
  605442: "red",
  605805: "red",
  606243: "gold",
  606693: "green",
  606953: "red",
  607306: "gold",
  607768: "green",
  608167: "purple",
  608553: "purple",
  608792: "purple",
  609077: "red",
  609339: "red",
  609756: "green",
  610096: "green",
  610564: "gold",
  610946: "gold",
  611376: "red",
  611638: "purple",
  611942: "red",
  612183: "green",
  612658: "green",
  612929: "purple",
  613232: "green",
  613590: "green",
  613956: "purple",
  614223: "red",
  614464: "green",
  614814: "green",
  615193: "purple",
  615515: "red",
  615941: "gold",
  616268: "gold",
  616632: "red",
  616934: "gold",
  617288: "purple",
  617760: "purple",
  618130: "green",
  618545: "purple",
  618808: "purple",
  619197: "purple",
  619543: "red",
  619965: "green",
  620222: "gold",
  620587: "green",
  620936: "purple",
  621262: "red",
  621502: "gold",
  621820: "red",
  622270: "purple",
  622530: "red",
  622882: "purple",
  623191: "purple",
  623520: "green",
  623815: "purple",
  624176: "gold",
  624565: "red",
  624992: "red",
  625373: "green",
  625658: "purple",
  626050: "green",
  626510: "red",
  626843: "purple",
  627262: "red",
  627501: "red",
  627896: "gold",
  628176: "red",
  628529: "green",
  628951: "purple",
  629262: "red",
  629545: "red",
  629878: "green",
  630192: "gold",
  630525: "purple",
  630909: "green",
  631349: "green",
  631620: "gold",
  632085: "purple",
  632380: "purple",
  632624: "purple",
  633044: "gold",
  633488: "red",
  633907: "green",
  634153: "purple",
  634570: "red",
  634911: "red",
  635372: "gold",
  635687: "green",
  636149: "purple",
  636449: "green",
  636728: "purple",
  637099: "red",
  637486: "red",
  637771: "red",
  638196: "gold",
  638479: "red",
  638848: "green",
  639125: "red",
  639376: "red",
  639716: "purple",
  640097: "purple",
  640339: "green",
  640693: "gold",
  640987: "gold",
  641416: "green",
  641757: "purple",
  642015: "red",
  642294: "red",
  642570: "green",
  642896: "green",
  643337: "purple",
  643688: "red",
  644056: "purple",
  644448: "green",
  644739: "purple",
  645185: "red",
  645548: "red",
  645991: "gold",
  646342: "red",
  646725: "green",
  647165: "green",
  647557: "gold",
  647903: "red",
  648201: "green",
  648468: "purple",
  648816: "red",
  649285: "gold",
  649549: "red",
  649900: "purple",
  650353: "purple",
  650710: "red",
  651164: "red",
  651558: "green",
  651841: "purple",
  652286: "purple",
  652673: "green",
  653059: "green",
  653301: "green",
  653614: "gold",
  653857: "green",
  654209: "purple",
  654671: "purple",
  655104: "red",
  655479: "purple",
  655717: "green",
  656009: "red",
  656268: "red",
  656620: "green",
  657049: "red",
  657397: "red",
  657708: "gold",
  658023: "purple",
  658479: "green",
  658824: "purple",
  659069: "green",
  659518: "gold",
  659895: "green",
  660227: "red",
  660678: "green",
  660942: "gold",
  661359: "red",
  661779: "red",
  662169: "red",
  662641: "gold",
  663064: "red",
  663311: "green",
  663625: "red",
  663879: "purple",
  664131: "green",
  664408: "red",
  664742: "red",
  664981: "gold",
  665260: "purple",
  665690: "green",
  666049: "gold",
  666339: "gold",
  666773: "red",
  667168: "green",
  667642: "red",
  668058: "gold",
  668502: "purple",
  668811: "purple",
  669229: "gold",
  669703: "purple",
  669985: "red",
  670309: "green",
  670692: "green",
  670999: "green",
  671436: "purple",
  671860: "gold",
  672198: "green",
  672602: "purple",
  672911: "gold",
  673328: "green",
  673594: "gold",
  673835: "green",
  674246: "red",
  674703: "green",
  675013: "gold",
  675330: "purple",
  675632: "green",
  675983: "purple",
  676324: "purple",
  676700: "red",
  676961: "gold",
  677206: "red",
  677654: "gold",
  678025: "purple",
  678337: "green",
  678585: "red",
  678991: "purple",
  679263: "gold",
  679715: "green",
  680081: "gold",
  680490: "red",
  680739: "red",
  681167: "purple",
  681619: "green",
  682044: "purple",
  682341: "gold",
  682653: "green",
  683128: "gold",
  683537: "purple",
  683978: "green",
  684222: "purple",
  684592: "red",
  684935: "purple",
  685365: "red",
  685767: "green",
  686027: "purple",
  686438: "red",
  686717: "red",
  687133: "gold",
  687446: "gold",
  687879: "purple",
  688346: "green",
  688618: "gold",
  689049: "gold",
  689462: "gold",
  689914: "gold",
  690195: "red",
  690470: "purple",
  690806: "gold",
  691066: "purple",
  691317: "purple",
  691569: "red",
  691893: "green",
  692355: "green",
  692678: "green",
  693091: "green",
  693501: "red",
  693791: "gold",
  694123: "purple",
  694534: "red",
  694794: "red",
  695221: "green",
  695458: "purple",
  695721: "purple",
  696026: "green",
  696441: "purple",
  696848: "purple",
  697316: "green",
  697712: "gold",
  697988: "red",
  698227: "purple",
  698680: "red",
  699113: "gold",
  699565: "purple",
  699904: "purple",
  700193: "green",
  700599: "purple",
  700963: "gold",
  701203: "red",
  701644: "purple",
  702082: "gold",
  702343: "green",
  702803: "red",
  703241: "gold",
  703697: "red",
  704139: "red",
  704450: "red",
  704845: "purple",
  705289: "gold",
  705608: "green",
  705918: "red",
  706319: "purple",
  706560: "gold",
  706923: "purple",
  707248: "purple",
  707722: "purple",
  708050: "gold",
  708459: "purple",
  708761: "red",
  709175: "gold",
  709603: "gold",
  709918: "gold",
  710245: "gold",
  710641: "green",
  711023: "gold",
  711437: "red",
  711848: "gold",
  712092: "red",
  712341: "gold",
  712645: "red",
  713101: "red",
  713528: "gold",
  713974: "purple",
  714367: "red",
  714781: "green",
  715171: "gold",
  715585: "purple",
  715971: "purple",
  716438: "purple",
  716697: "red",
  717064: "purple",
  717439: "green",
  717789: "red",
  718218: "gold",
  718692: "gold",
  719124: "purple",
  719550: "red",
  719947: "green",
  720319: "gold",
  720581: "red",
  721005: "green",
  721247: "gold",
  721621: "purple",
  721914: "red",
  722337: "gold",
  722698: "green",
  723055: "purple",
  723474: "purple",
  723882: "red",
  724251: "red",
  724625: "purple",
  724928: "green",
  725247: "gold",
  725611: "red",
  726059: "gold",
  726301: "green",
  726678: "gold",
  726964: "red",
  727205: "gold",
  727454: "gold",
  727915: "gold",
  728330: "red",
  728646: "gold",
  729036: "purple",
  729294: "green",
  729588: "red",
  730050: "gold",
  730290: "gold",
  730644: "green",
  730940: "green",
  731319: "green",
  731556: "red",
  731941: "green",
  732199: "gold",
  732554: "green",
  733021: "red",
  733286: "red",
  733600: "red",
  733928: "gold",
  734294: "purple",
  734769: "purple",
  735103: "purple",
  735530: "gold",
  735849: "red",
  736212: "gold",
  736452: "purple",
  736744: "purple",
  736993: "purple",
  737367: "purple",
  737777: "purple",
  738189: "green",
  738463: "green",
  738906: "purple",
  739347: "red",
  739705: "purple",
  740159: "gold",
  740619: "purple",
  740935: "green",
  741398: "red",
  741642: "gold",
  741927: "green",
  742222: "gold",
  742689: "purple",
  742948: "green",
  743313: "purple",
  743742: "red",
  744049: "purple",
  744368: "green",
  744662: "green",
  744904: "green",
  745159: "red",
  745597: "red",
  745871: "gold",
  746277: "green",
  746645: "purple",
  746924: "gold",
  747226: "red",
  747478: "gold",
  747763: "purple",
  748157: "red",
  748500: "purple",
  748851: "gold",
  749276: "red",
  749662: "red",
  749972: "purple",
  750227: "green",
  750555: "red",
  750992: "green",
  751423: "purple",
  751821: "gold",
  752181: "green",
  752607: "green",
  752889: "green",
  753312: "gold",
  753760: "green",
  754072: "red",
  754481: "gold",
  754731: "red",
  755063: "red",
  755303: "red",
  755669: "purple",
  755911: "red",
  756299: "purple",
  756714: "red",
  757066: "gold",
  757510: "red",
  757975: "green",
  758273: "purple",
  758577: "purple",
  758873: "gold",
  759306: "green",
  759762: "red",
  760170: "red",
  760510: "purple",
  760939: "red",
  761385: "green",
  761681: "red",
  762112: "red",
  762547: "green",
  763001: "green",
  763374: "gold",
  763792: "green",
  764109: "gold",
  764555: "gold",
  764983: "gold",
  765371: "red",
  765677: "purple",
  766022: "red",
  766379: "gold",
  766735: "purple",
  766995: "green",
  767465: "green",
  767869: "gold",
  768342: "purple",
  768739: "red",
  768977: "gold",
  769252: "gold",
  769531: "gold",
  769931: "red",
  770322: "red",
  770598: "green",
  770950: "green",
  771187: "green",
  771651: "red",
  772080: "purple",
  772331: "purple",
  772700: "gold",
  773081: "gold",
  773358: "gold",
  773683: "green",
  774102: "purple",
  774486: "purple",
  774934: "green",
  775372: "red",
  775784: "gold",
  776164: "red",
  776408: "purple",
  776716: "gold",
  777016: "red",
  777459: "green",
  777734: "purple",
  778126: "purple",
  778531: "gold",
  779004: "green",
  779416: "purple",
  779869: "gold",
  780244: "red",
  780534: "green",
  780968: "gold",
  781338: "purple",
  781768: "green",
  782098: "green",
  782531: "purple",
  782890: "green",
  783351: "gold",
  783780: "purple",
  784240: "green",
  784524: "green",
  784932: "green",
  785212: "purple",
  785552: "red",
  785874: "red",
  786338: "green",
  786660: "purple",
  786957: "red",
  787324: "red",
  787732: "gold",
  788015: "green",
  788417: "green",
  788663: "gold",
  789059: "purple",
  789513: "gold",
  789760: "red",
  790191: "purple",
  790501: "green",
  790964: "purple",
  791210: "red",
  791607: "red",
  791932: "purple",
  792291: "gold",
  792564: "green",
  792820: "red",
  793268: "red",
  793633: "red",
  793933: "gold",
  794183: "purple",
  794511: "purple",
  794932: "purple",
  795353: "gold",
  795725: "gold",
  796009: "gold",
  796456: "purple",
  796843: "purple",
  797129: "green",
  797443: "green",
  797722: "green",
  797999: "green",
  798302: "green",
  798721: "red",
  799119: "red",
  799501: "purple",
  799844: "gold",
  800214: "red",
  800629: "red",
  800958: "purple",
  801238: "red",
  801590: "green",
  801984: "green",
  802451: "red",
  802802: "red",
  803195: "purple",
  803528: "green",
  803983: "purple",
  804221: "green",
  804479: "gold",
  804852: "red",
  805157: "purple",
  805488: "purple",
  805901: "green",
  806141: "red",
  806420: "purple",
  806703: "green",
  806952: "gold",
  807263: "purple",
  807589: "red",
  807952: "gold",
  808274: "gold",
  808527: "green",
  808771: "green",
  809091: "green",
  809563: "green",
  809845: "green",
  810299: "gold",
  810669: "red",
  810943: "gold",
  811186: "green",
  811530: "gold",
  811829: "gold",
  812097: "red",
  812443: "gold",
  812867: "red",
  813266: "green",
  813677: "gold",
  813967: "green",
  814215: "green",
  814551: "green",
  814807: "green",
  815274: "green",
  815539: "gold",
  815860: "green",
  816248: "green",
  816561: "red",
  817018: "red",
  817308: "green",
  817617: "purple",
  817868: "green",
  818212: "gold",
  818683: "green",
  819092: "gold",
  819394: "purple",
  819796: "red",
  820233: "gold",
  820628: "gold",
  821048: "green",
  821335: "green",
  821640: "green",
  821891: "gold",
  822215: "red",
  822514: "purple",
  822929: "purple",
  823295: "red",
  823754: "green",
  824014: "purple",
  824424: "gold",
  824711: "purple",
  825130: "gold",
  825559: "purple",
  825962: "gold",
  826219: "gold",
  826552: "purple",
  826973: "green",
  827246: "red",
  827675: "green",
  827967: "green",
  828247: "green",
  828501: "red",
  828962: "gold",
  829359: "purple",
  829607: "gold",
  829974: "gold",
  830442: "purple",
  830877: "purple",
  831283: "red",
  831627: "red",
  832070: "red",
  832527: "green",
  832924: "green",
  833379: "gold",
  833760: "purple",
  834122: "purple",
  834406: "green",
  834670: "red",
  835014: "gold",
  835399: "purple",
  835661: "red",
  836068: "red",
  836466: "green",
  836911: "gold",
  837381: "green",
  837748: "gold",
  838176: "purple",
  838545: "gold",
  838878: "green",
  839192: "red",
  839452: "gold",
  839887: "purple",
  840268: "red",
  840735: "green",
  840996: "red",
  841471: "red",
  841719: "purple",
  842188: "gold",
  842434: "purple",
  842690: "gold",
  842996: "purple",
  843373: "gold",
  843670: "red",
  844131: "purple",
  844572: "gold",
  844826: "red",
  845277: "purple",
  845681: "gold",
  846125: "red",
  846378: "green",
  846773: "gold",
  847205: "purple",
  847669: "red",
  848130: "green",
  848498: "green",
  848761: "red",
  849128: "red",
  849456: "red",
  849697: "red",
  849955: "green",
  850345: "green",
  850584: "purple",
  850963: "red",
  851236: "red",
  851509: "red",
  851917: "purple",
  852384: "purple",
  852804: "red",
  853256: "red",
  853575: "green",
  853877: "red",
  854338: "green",
  854618: "red",
  855092: "gold",
  855333: "green",
  855799: "gold",
  856191: "purple",
  856665: "gold",
  856960: "red",
  857411: "green",
  857751: "green",
  858129: "gold",
  858543: "red",
  858818: "green",
  859074: "gold",
  859434: "red",
  859708: "red",
  860176: "red",
  860578: "gold",
  860888: "green",
  861339: "green",
  861737: "purple",
  862176: "purple",
  862622: "purple",
  863058: "red",
  863414: "green",
  863880: "red",
  864193: "purple",
  864449: "green",
  864859: "red",
  865170: "gold",
  865429: "gold",
  865852: "purple",
  866322: "green",
  866560: "purple",
  866916: "gold",
  867381: "red",
  867833: "green",
  868242: "gold",
  868663: "red",
  868991: "red",
  869365: "green",
  869723: "purple",
  870017: "purple",
  870295: "gold",
  870651: "red",
  870895: "gold",
  871261: "purple",
  871562: "gold",
  871967: "purple",
  872395: "purple",
  872763: "green",
  873060: "purple",
  873399: "purple",
  873656: "purple",
  873959: "gold",
  874258: "gold",
  874660: "red",
  875022: "green",
  875359: "purple",
  875665: "green",
  876099: "purple",
  876339: "gold",
  876793: "gold",
  877170: "red",
  877519: "green",
  877968: "red",
  878209: "gold",
  878606: "red",
  878873: "gold",
  879319: "red",
  879592: "purple",
  879838: "purple",
  880232: "red",
  880471: "red",
  880878: "purple",
  881273: "gold",
  881730: "green",
  882113: "gold",
  882362: "red",
  882815: "green",
  883279: "green",
  883524: "purple",
  883780: "red",
  884039: "gold",
  884509: "red",
  884877: "purple",
  885238: "red",
  885661: "gold",
  886042: "green",
  886406: "red",
  886805: "green",
  887067: "green",
  887378: "purple",
  887827: "purple",
  888201: "red",
  888620: "green",
  888867: "red",
  889148: "gold",
  889416: "gold",
  889775: "purple",
  890146: "purple",
  890505: "green",
  890766: "purple",
  891122: "purple",
  891393: "red",
  891710: "green",
  892040: "gold",
  892501: "red",
  892769: "red",
  893048: "red",
  893358: "green",
  893665: "green",
  894099: "gold",
  894433: "purple",
  894884: "green",
  895287: "purple",
  895691: "red",
  896081: "green",
  896464: "purple",
  896934: "red",
  897189: "green",
  897548: "green",
  897838: "purple",
  898295: "green",
  898573: "purple",
  898929: "gold",
  899375: "red",
  899847: "gold",
  900101: "gold",
  900364: "green",
  900624: "red",
  901046: "red",
  901318: "gold",
  901774: "purple",
  902121: "gold",
  902398: "green",
  902753: "green",
  903082: "gold",
  903486: "gold",
  903783: "green",
  904193: "red",
  904487: "green",
  904727: "purple",
  905190: "gold",
  905485: "purple",
  905907: "green",
  906219: "green",
  906633: "purple",
  906971: "gold",
  907366: "red",
  907781: "red",
  908160: "red",
  908448: "gold",
  908882: "gold",
  909343: "red",
  909674: "gold",
  910125: "green",
  910529: "green",
  910823: "purple",
  911183: "purple",
  911619: "gold",
  912080: "green",
  912449: "gold",
  912898: "gold",
  913250: "green",
  913557: "red",
  914024: "purple",
  914417: "purple",
  914726: "green",
  915098: "purple",
  915500: "gold",
  915752: "purple",
  916151: "purple",
  916432: "red",
  916803: "gold",
  917081: "gold",
  917483: "purple",
  917834: "green",
  918181: "gold",
  918598: "purple",
  918908: "green",
  919266: "red",
  919708: "green",
  919958: "purple",
  920389: "green",
  920699: "green",
  921109: "gold",
  921519: "red",
  921926: "green",
  922282: "green",
  922623: "gold",
  923020: "purple",
  923485: "purple",
  923908: "red",
  924370: "red",
  924729: "gold",
  925033: "gold",
  925309: "green",
  925729: "red",
  926111: "purple",
  926573: "green",
  926934: "green",
  927193: "gold",
  927576: "gold",
  928006: "red",
  928300: "green",
  928566: "gold",
  928958: "green",
  929241: "gold",
  929562: "gold",
  929836: "purple",
  930230: "red",
  930702: "purple",
  931033: "gold",
  931387: "purple",
  931771: "green",
  932078: "green",
  932535: "gold",
  932915: "gold",
  933264: "red",
  933579: "purple",
  934031: "gold",
  934339: "green",
  934692: "gold",
  934966: "green",
  935248: "red",
  935656: "gold",
  935917: "red",
  936184: "purple",
  936505: "gold",
  936925: "gold",
  937281: "red",
  937678: "gold",
  938089: "purple",
  938380: "red",
  938702: "green",
  939034: "red",
  939437: "green",
  939779: "purple",
  940248: "purple",
  940507: "gold",
  940944: "purple",
  941347: "red",
  941641: "red",
  941881: "gold",
  942130: "red",
  942558: "gold",
  942934: "green",
  943215: "green",
  943645: "gold",
  944033: "green",
  944382: "gold",
  944767: "green",
  945055: "purple",
  945408: "red",
  945655: "purple",
  946041: "gold",
  946491: "purple",
  946827: "green",
  947190: "green",
  947611: "green",
  947942: "green",
  948366: "red",
  948732: "red",
  949000: "green",
  949448: "purple",
  949781: "red",
  950130: "green",
  950394: "green",
  950818: "gold",
  951236: "gold",
  951488: "red",
  951921: "red",
  952174: "green",
  952603: "purple",
  952989: "gold",
  953294: "red",
  953734: "gold",
  954146: "red",
  954501: "purple",
  954923: "gold",
  955228: "red",
  955544: "purple",
  955811: "green",
  956258: "red",
  956686: "gold",
  957125: "gold",
  957592: "red",
  958039: "green",
  958435: "purple",
  958823: "purple",
  959231: "gold",
  959477: "gold",
  959914: "purple",
  960165: "green",
  960575: "gold",
  960966: "purple",
  961215: "gold",
  961512: "green",
  961985: "gold",
  962289: "green",
  962616: "red",
  963081: "gold",
  963329: "red",
  963804: "gold",
  964255: "purple",
  964492: "gold",
  964906: "green",
  965240: "red",
  965605: "red",
  966025: "red",
  966401: "green",
  966698: "purple",
  967033: "gold",
  967479: "gold",
  967731: "green",
  968191: "purple",
  968546: "red",
  968972: "red",
  969396: "gold",
  969640: "green",
  969998: "gold",
  970276: "gold",
  970742: "green",
  971112: "gold",
  971449: "gold",
  971917: "purple",
  972154: "purple",
  972544: "green",
  972925: "gold",
  973201: "red",
  973486: "gold",
  973806: "green",
  974144: "green",
  974576: "green",
  974831: "purple",
  975133: "green",
  975452: "green",
  975771: "red",
  976233: "purple",
  976675: "green",
  976998: "purple",
  977262: "purple",
  977512: "gold",
  977868: "purple",
  978263: "gold",
  978542: "red",
  978889: "red",
  979194: "gold",
  979469: "purple",
  979822: "gold",
  980209: "purple",
  980545: "purple",
  980799: "purple",
  981107: "gold",
  981485: "gold",
  981801: "green",
  982142: "green",
  982395: "green",
  982829: "gold",
  983170: "purple",
  983558: "purple",
  983839: "purple",
  984292: "purple",
  984539: "red",
  984830: "green",
  985095: "gold",
  985461: "green",
  985925: "green",
  986379: "red",
  986712: "purple",
  987079: "gold",
  987388: "purple",
  987808: "gold",
  988144: "red",
  988475: "purple",
  988936: "red",
  989327: "gold",
  989575: "green",
  989860: "green",
  990219: "green",
  990685: "gold",
  990945: "red",
  991229: "purple",
  991670: "red",
  992127: "red",
  992538: "purple",
  992931: "red",
  993334: "red",
  993782: "gold",
  994179: "gold",
  994428: "purple",
  994900: "gold",
  995302: "red",
  995752: "red",
  996164: "red",
  996514: "purple",
  996961: "green",
  997353: "purple",
  997748: "purple",
  998174: "green",
  998444: "red",
  998732: "green",
  999066: "gold",
  999444: "green",
  999748: "gold",
};

export default INDICES;
